#about #about-header {
  background: url("./img/about-header.png") no-repeat center;
  background-size: cover;
  padding-bottom: 58.3%;
  margin: 0 -15px;
  position: relative;
}
@media screen and (min-width: 768px) {
  #about #about-header {
    padding-bottom: 41.3%;
    margin: 0;
  }
}
#about #about-header h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  text-align: center;
  color: black;
}
@media screen and (min-width: 768px) {
  #about #about-header h1 {
    font-size: 55px;
  }
}
@media screen and (min-width: 992px) {
  #about #about-header h1 {
    font-size: 70px;
  }
}
#about #about-description {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
  #about #about-description {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 992px) {
  #about #about-description {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
#about #about-description .vize {
  position: relative;
}
#about #about-description .vize h2 {
  display: block;
  background: white;
  z-index: 2;
  position: relative;
  float: none !important;
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) {
  #about #about-description .vize h2 {
    display: inline-block;
    padding-left: 45px;
    padding-right: 45px;
    margin-bottom: 0;
  }
}
#about #about-description .vize .vize-dots {
  display: none;
}
@media screen and (min-width: 1200px) {
  #about #about-description .vize .vize-dots {
    display: block;
    position: absolute;
    top: -135px;
    left: 47px;
  }
}
#about #about-description .description span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #about #about-description .description span {
    font-size: 22px;
    font-weight: 500;
  }
}
#about #about-description-image-blocks #about-prace {
  background: url("./img/about-prace.png");
}
#about #about-description-image-blocks #about-vasen {
  background: url("./img/about-vasen.png");
  padding: 0;
}
#about #about-description-image-blocks #about-vasen .black-overlay {
  padding: 0 !important;
}
#about #about-description-image-blocks #about-svoboda {
  background: url("./img/about-svoboda.png");
}
#about #about-description-image-blocks .about-description-image-block {
  color: white;
  min-height: 480px;
  background-size: cover !important;
  background-clip: content-box !important;
  position: relative;
  transition: all 0.3s ease;
}
#about #about-description-image-blocks .about-description-image-block h2 {
  position: absolute;
  font-size: 55px;
  top: 50%;
  left: 50%;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%);
}
#about #about-description-image-blocks .about-description-image-block span {
  width: 80%;
  display: block;
  margin: auto auto;
  margin-top: 220px;
  transition: all 0.3s ease;
  opacity: 0;
}
#about #about-description-image-blocks .about-description-image-block .black-overlay {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  height: 100%;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
  background-clip: content-box !important;
}
#about #about-description-image-blocks .about-description-image-block:hover {
  transform: scale(1.1);
  z-index: 2;
}
#about #about-description-image-blocks .about-description-image-block:hover h2 {
  transform: translate(-50%, -170%);
}
#about #about-description-image-blocks .about-description-image-block:hover .black-overlay {
  background: rgba(0, 0, 0, 0.6);
}
#about #about-description-image-blocks .about-description-image-block:hover span {
  opacity: 1;
}
#about #about-description-history {
  padding-top: 100px;
}
@media screen and (min-width: 768px) {
  #about #about-description-history {
    padding: 100px 15px 15px;
  }
}
#about #about-description-history h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 75px;
  font-size: 40px;
}
@media screen and (min-width: 768px) {
  #about #about-description-history h2 {
    margin-top: 50px;
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  #about #about-description-history h2 {
    padding-bottom: 50px;
    margin-top: 0;
    margin-left: 50px;
  }
}
#about #about-description-history b {
  font-weight: 600;
}
#about #about-description-history .left,
#about #about-description-history .right {
  background: #f5f5f5;
}
#about #about-description-history .left span,
#about #about-description-history .right span {
  font-size: 18px;
}
#about #about-description-history .left {
  text-align: right;
}
@media screen and (min-width: 768px) {
  #about #about-description-history .left {
    border-right: 5px solid white;
  }
}
#about #about-description-history .left .yellow {
  position: relative;
  padding: 25px;
  padding-right: 80px;
  background: #fdde00;
}
#about #about-description-history .left .yellow img {
  right: -75px;
  top: 5px;
  width: 150px;
  position: absolute;
}
#about #about-description-history .left .history-left-1 {
  margin-top: 50px;
}
#about #about-description-history .left .history-left-2 {
  margin-top: 50px;
}
@media screen and (min-width: 768px) {
  #about #about-description-history .left .history-left-2 {
    margin-top: 110px;
  }
}
#about #about-description-history .left .history-left-3 {
  margin-top: 50px;
}
@media screen and (min-width: 768px) {
  #about #about-description-history .left .history-left-3 {
    margin-top: 115px;
  }
}
@media screen and (min-width: 992px) {
  #about #about-description-history .left .history-left-3 {
    margin-top: 130px;
  }
}
#about #about-description-history .left .history-left-4 {
  margin-top: 50px;
}
@media screen and (min-width: 768px) {
  #about #about-description-history .left .history-left-4 {
    margin-top: 30px;
  }
}
#about #about-description-history .left .history-left-5 {
  margin-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  #about #about-description-history .left .history-left-5 {
    margin-top: 75px;
  }
}
@media screen and (min-width: 992px) {
  #about #about-description-history .left .history-left-5 {
    margin-top: 130px;
  }
}
#about #about-description-history .left .history-left:after {
  display: inline-block;
  content: "";
  border-top: 0.25rem solid white;
  width: 8rem;
  margin: -2px 7rem;
  position: absolute;
  top: 15px;
  right: -250px;
}
#about #about-description-history .left .history-left .mobile {
  display: none;
}
#about #about-description-history .right {
  text-align: right;
}
#about #about-description-history .right .history-right {
  position: relative;
}
#about #about-description-history .right .history-right:before {
  display: inline-block;
  content: "";
  border-top: 0.25rem solid #fdde00;
  width: 8rem;
  margin: -2px 7rem;
  position: absolute;
  top: 40px;
  left: -111px;
  height: 4px;
}
#about #about-description-history .right .history-right .yellow {
  position: relative;
  text-align: left;
  padding: 25px;
  padding-right: 80px;
  background: #fdde00;
}
#about #about-description-history .right .history-right .yellow img {
  right: -75px;
  top: 5px;
  width: 150px;
  position: absolute;
}
#about #about-description-history .right .history-right-1 {
  margin-top: 105px;
}
#about #about-description-history .right .history-right-1 img {
  right: -65px !important;
  top: -27px !important;
  width: 127px !important;
}
#about #about-description-history .right .history-right-2 {
  margin-top: 110px;
}
@media screen and (min-width: 992px) {
  #about #about-description-history .right .history-right-2 {
    margin-top: 150px;
  }
}
#about #about-description-history .right .history-right-3 {
  margin-top: 135px;
}
@media screen and (min-width: 992px) {
  #about #about-description-history .right .history-right-3 {
    margin-top: 220px;
  }
}
#about #about-description-history .right .history-right-3 img {
  right: -67px !important;
  top: -41px !important;
  width: 150px !important;
}
#about #about-description-history .right .history-right-4 {
  margin-top: 50px;
}
#about #about-description-history .right .history-right-5 {
  margin-top: 50px;
  padding-bottom: 50px;
}
#about #about-description-history .history-content {
  position: relative;
}
#about #about-description-history .history-content .history-date-tags {
  z-index: 2;
  position: absolute;
  top: 0;
  margin: auto auto;
  float: none;
  left: 50%;
  transform: translateX(-50%);
}
#about #about-description-history .history-content .history-date-tags .history-tag {
  background: #f5f5f5;
  padding: 5px 15px;
  font-weight: 600;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  position: relative;
}
#about #about-description-history .history-content .history-date-tags .history-tag.history-tag-1 {
  margin-top: -30px;
}
#about #about-description-history .history-content .history-date-tags .history-tag.history-tag-2 {
  margin-top: 170px;
}
#about #about-description-history .history-content .history-date-tags .history-tag.history-tag-3 {
  margin-top: 80px;
}
#about #about-description-history .history-content .history-date-tags .history-tag.history-tag-4 {
  margin-top: 50px;
}
#about #about-description-history .history-content .history-date-tags .history-tag.history-tag-5 {
  margin-top: 170px;
}
@media screen and (min-width: 992px) {
  #about #about-description-history .history-content .history-date-tags .history-tag.history-tag-5 {
    margin-top: 100px;
  }
}
#about #about-description-history .history-content .history-date-tags .history-tag.history-tag-6 {
  margin-top: 125px;
}
@media screen and (min-width: 992px) {
  #about #about-description-history .history-content .history-date-tags .history-tag.history-tag-6 {
    margin-top: 190px;
  }
}
#about #about-description-history .history-content .history-date-tags .history-tag span {
  font-weight: 600;
}
#about #about-description-history .history-content .history-tag--mobile {
  margin-bottom: 30px;
}
#about #about-description-history .history-content .history-tag--mobile.history-tag--mobile--2010 {
  margin-top: 50px;
}
#about #about-description-history .history-content .history-tag--mobile span {
  background: #f5f5f5;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  display: inline-block;
}
#about #about-description-history .project-numbers {
  margin-top: 50px;
}
#about #about-description-history .project-numbers .number {
  display: block;
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
}
#about #about-description-history .project-numbers .project-number-box {
  background: #ffffff;
  background: linear-gradient(180deg, #f5f5f5 0%, #f5f5f5 7.14%, #ffffff 7.14%, #ffffff 14.28%, #f5f5f5 14.28%, #f5f5f5 21.42%, #ffffff 21.42%, #ffffff 28.56%, #f5f5f5 28.56%, #f5f5f5 35.7%, #ffffff 35.7%, #ffffff 42.84%, #f5f5f5 42.84%, #f5f5f5 49.98%, #ffffff 49.98%, #ffffff 57.12%, #f5f5f5 57.12%, #f5f5f5 64.26%, #ffffff 64.26%, #ffffff 71.4%, #f5f5f5 71.4%, #f5f5f5 78.54%, #ffffff 78.54%, #ffffff 85.68%, #f5f5f5 85.68%, #f5f5f5 92.82%, #ffffff 92.82%, #ffffff 100%);
  padding-top: 68px;
  padding-bottom: 68px;
  margin-bottom: 30px;
}
#about #about-description-history .project-numbers .project-number-box:not(:nth-child(even)) {
  border-right: 5px solid white;
}
@media screen and (min-width: 768px) {
  #about #about-description-history .project-numbers .project-number-box:not(:last-child) {
    border-right: 5px solid white;
  }
}
#about #about-people {
  margin-top: 50px;
  text-align: left;
}
#about #about-people .people-description {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}
#about #about-people .people-description h2 {
  font-size: 40px;
}
#about #about-people .people-description span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #about #about-people .people-description span {
    font-size: 17px;
  }
}
#about #about-people .people-description button {
  display: block !important;
  margin-top: 30px;
  float: none !important;
  left: 0 !important;
  position: relative;
  margin-left: 0;
}
#about #about-team {
  padding: 50px 30px 0;
}
@media screen and (min-width: 768px) {
  #about #about-team h2 {
    padding-left: 50px;
  }
}
#about #about-team .about-team-block {
  padding: 15px;
  position: relative;
  transition: all 0.3s ease;
}
#about #about-team .about-team-block .black-overlay {
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  transition: all 0.3s ease;
  color: #fff;
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  background-clip: content-box;
  padding-bottom: 15px;
}
#about #about-team .about-team-block .black-overlay .overlay-inner {
  padding: 30px;
  margin-top: 45px;
}
#about #about-team .about-team-block .black-overlay h4 {
  opacity: 0;
  font-size: 35px;
  font-weight: 400;
  transition: all 0.5s ease;
}
#about #about-team .about-team-block .black-overlay span {
  transition: all 0.5s ease;
  opacity: 0;
  font-size: 16px;
  font-weight: 400;
}
#about #about-team .about-team-block:hover {
  cursor: pointer;
  transform: scale(1.1);
  z-index: 2;
}
#about #about-team .about-team-block:hover .black-overlay {
  opacity: 1;
}
#about #about-team .about-team-block:hover .black-overlay h4,
#about #about-team .about-team-block:hover .black-overlay span {
  opacity: 1;
}
#about #about-team .about-team-block h5 {
  text-align: center;
  margin-top: 10px;
}
#about #about-team .about-team-row {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  margin-right: 10px;
}
#about #about-team .about-team-row:before {
  position: absolute;
  content: url("./img/arrow-left-black.svg");
  /* FontAwesome Unicode */
  width: 19px;
  height: 19px;
  display: inline-block;
  left: 0;
  top: 35%;
  transform: translateY(-50%);
}
#about #about-team .about-team-row:after {
  position: absolute;
  content: url("./img/arrow-right-black.svg");
  /* FontAwesome Unicode */
  width: 19px;
  height: 19px;
  display: inline-block;
  right: 0;
  top: 35%;
  transform: translateY(-50%);
}
#about #about-process {
  background: #393737;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 50px -15px 0;
  position: relative;
}
@media screen and (min-width: 768px) {
  #about #about-process {
    margin: 50px 0 0;
  }
}
@media screen and (min-width: 992px) {
  #about #about-process {
    padding: 50px 60px;
    margin-top: 150px;
  }
}
#about #about-process h2 {
  text-align: left;
  display: block;
}
#about #about-process .about-process-header {
  padding-top: 200px;
  padding-bottom: 50px;
  text-align: left;
  position: relative;
}
#about #about-process .animated-dots {
  display: none;
}
@media screen and (min-width: 992px) {
  #about #about-process .animated-dots {
    display: block;
    top: -232px;
    right: 50px;
    position: absolute;
  }
}
#about #about-process span {
  font-size: 18px;
  font-weight: 300;
}
.element-animation {
  animation: animationFrames linear 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames linear 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames linear 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames linear 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}
@keyframes animationFrames {
  0% {
    transform: scaleY(1);
  }
  30% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(-1);
  }
  70% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@-moz-keyframes animationFrames {
  0% {
    -moz-transform: scaleY(1);
  }
  30% {
    -moz-transform: scaleY(0);
  }
  50% {
    -moz-transform: scaleY(-1);
  }
  70% {
    -moz-transform: scaleY(0);
  }
  100% {
    -moz-transform: scaleY(1);
  }
}
@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: scaleY(1);
  }
  30% {
    -webkit-transform: scaleY(0);
  }
  50% {
    -webkit-transform: scaleY(-1);
  }
  70% {
    -webkit-transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
  }
}
@-o-keyframes animationFrames {
  0% {
    -o-transform: scaleY(1);
  }
  30% {
    -o-transform: scaleY(0);
  }
  50% {
    -o-transform: scaleY(-1);
  }
  70% {
    -o-transform: scaleY(0);
  }
  100% {
    -o-transform: scaleY(1);
  }
}
@-ms-keyframes animationFrames {
  0% {
    -ms-transform: scaleY(1);
  }
  30% {
    -ms-transform: scaleY(0);
  }
  50% {
    -ms-transform: scaleY(-1);
  }
  70% {
    -ms-transform: scaleY(0);
  }
  100% {
    -ms-transform: scaleY(1);
  }
}
@media (max-width: 991px) {
  #about #about-description .vize {
    min-width: unset !important;
  }
  #about #about-description-image-blocks .about-description-image-block h2 {
    font-size: 40px;
  }
  #about #about-description-image-blocks .about-description-image-block {
    min-height: 350px;
  }
  #about #about-description-image-blocks .about-description-image-block span {
    margin-top: 155px;
  }
  #about #about-description-history .project-numbers .project-number-box {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #about #about-people .people-description {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0;
  }
  #about #about-process .about-process-header img {
    display: none;
  }
  #about #about-process .about-process-header {
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .history-left {
    text-align: center;
  }
  .history-left .mobile {
    text-align: center;
    display: block !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 30px;
  }
  #about #about-description-history .left .history-left:after {
    display: none;
  }
  #about #about-description-history .left .yellow img {
    right: 0;
  }
  #about #about-description-history .right {
    display: none;
  }
  #about #about-description .vize {
    max-width: 380px;
  }
  #about #about-description-image-blocks #about-vasen {
    padding: 15px;
  }
  #about #about-description-image-blocks #about-vasen .black-overlay {
    padding: 15px !important;
    top: 0;
  }
  #about #about-people .people-description {
    margin-top: 30px;
  }
}
@media (max-width: 600px) {
  #about #about-description-history .left .history-left .mobile {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  #about #about-description-history .left .yellow img {
    width: 70px;
    right: -15px;
  }
}
@media (max-width: 380px) {
  #about #about-description .vize {
    max-width: 100%;
  }
  #about #about-description .vize .vize-dots {
    max-width: 75%;
  }
}
@media (max-width: 320px) {
  #about #about-description .vize h2 {
    top: -12px;
    left: 6px;
  }
}
.video-container {
  margin-top: 20px;
  position: relative;
  width: 100%;
}
.video-container:before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.video-container iframe {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.video-title {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 21px;
  display: block;
}
#home-quality-politics .container {
  padding-top: 80px;
  border-bottom: lightgrey;
}
.home #home-header {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 461px;
  text-align: left;
  color: white;
  position: relative;
  padding: 80px;
}
.home #home-header h1 {
  font-size: 48px;
}
.home #home-header h3 {
  font-size: 23px;
}
.home #home-header h1,
.home #home-header h3 {
  text-align: left;
}
.home #home-header .button-bottom {
  position: absolute;
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .home #home-header .home-header-buttons {
    position: absolute;
    width: calc(100% - 160px);
    bottom: 30px;
  }
}
.home .loaderProjects {
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  padding-top: 300px;
}
.home#home-services-expertise {
  background: #f5f5f5;
  padding: 25px 25px 45px;
}
@media screen and (min-width: 768px) {
  .home#home-services-expertise {
    padding: 50px 25px 100px;
  }
}
.home#home-services-expertise h2 {
  margin: 0 -15px;
}
@media screen and (min-width: 768px) {
  .home#home-services-expertise h2 {
    margin: 0;
  }
}
.home#home-services-expertise .services-expertise-box {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .home#home-services-expertise .services-expertise-box {
    margin-top: 50px;
  }
}
.home#home-services-expertise .services-expertise-box .services-box,
.home#home-services-expertise .services-expertise-box .expertise-box {
  background: #ffffff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
}
.home#home-services-expertise .services-expertise-box .services-box h3.services-expertise-box-header,
.home#home-services-expertise .services-expertise-box .expertise-box h3.services-expertise-box-header {
  background: #FCDC00;
  margin: 0 -15px;
  padding: 8px 25px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .home#home-services-expertise .services-expertise-box .services-box h3.services-expertise-box-header,
  .home#home-services-expertise .services-expertise-box .expertise-box h3.services-expertise-box-header {
    padding: 25px 50px;
    font-size: 22px;
  }
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content {
  padding: 15px 10px 25px;
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content:after,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content:after {
  content: ' ';
  display: table;
  clear: both;
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li {
  margin-bottom: 12px;
  font-size: 12px;
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li a,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li a {
  text-decoration: none;
  font-size: 12px;
  color: #9B9B9B;
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li a:hover,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li a:hover,
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li a:active,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li a:active,
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li a:focus,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li a:focus {
  color: black;
}
@media screen and (min-width: 768px) {
  .home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul > ul,
  .home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul > ul {
    margin-left: 30px;
  }
}
.home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content .expertise-list-container,
.home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content .expertise-list-container {
  width: 50%;
  float: left;
}
@media screen and (min-width: 768px) {
  .home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content,
  .home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content {
    padding: 40px 35px;
  }
  .home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li,
  .home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li {
    margin-bottom: 20px;
    font-size: 22px;
  }
  .home#home-services-expertise .services-expertise-box .services-box .services-expertise-box-content ul li a,
  .home#home-services-expertise .services-expertise-box .expertise-box .services-expertise-box-content ul li a {
    font-size: 22px;
  }
}
.home#home-services-expertise .services-expertise-box .services-box {
  margin-bottom: 10px;
}
@media screen and (min-width: 992px) {
  .home#home-services-expertise .services-expertise-box .services-box {
    position: relative;
    z-index: 10;
    margin-bottom: 0;
  }
}
.home #home-about-us {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 50px;
}
.home #home-about-us .slick-slider {
  z-index: 2;
  background: white;
}
.home #home-about-us .slick-slide img {
  display: inline-block;
  max-width: 75%;
  max-height: 230px;
}
@media screen and (min-width: 768px) {
  .home #home-about-us .slick-slide img {
    max-width: 100%;
    max-height: none;
  }
}
.home #home-about-us .second-row {
  margin-top: 75px;
}
@media screen and (min-width: 768px) {
  .home #home-about-us .second-row {
    margin-top: 50px;
  }
}
.home #home-about-us .wide-range {
  padding-top: 30px;
}
.home #home-about-us .wide-range h3 {
  font-weight: bold;
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .home #home-about-us .wide-range h3 {
    font-size: 30px;
    margin-bottom: 50px;
  }
}
.home #home-about-us .wide-range span {
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .home #home-about-us .wide-range span {
    font-size: 17px;
  }
}
.home #home-about-us .about-us-slider-container {
  padding: 0 30px;
}
@media screen and (min-width: 992px) {
  .home #home-about-us .about-us-slider-container {
    padding: 0;
  }
}
.home #home-about-us .slick-arrow {
  width: 50px !important;
  height: 50px  !important;
}
.home #home-about-us .slick-next,
.home #home-about-us .slick-next:before {
  width: 50%;
  height: 100%;
  display: block;
  background-size: 65% !important;
  float: right;
}
.home #home-about-us .slick-prev,
.home #home-about-us .slick-prev:before {
  width: 50%;
  height: 100%;
  display: block;
  background-size: 65% !important;
  float: left;
}
.home #home-about-us .slick-dots li button:before {
  font-size: 15px;
}
.home#home-process {
  background: #393737;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
.home #home-partner {
  padding: 50px;
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: left;
}
.home #home-partner .mobile {
  margin-right: 35px;
}
.home #home-partner .desktop {
  margin-right: 50px;
}
.home #home-partner h2 {
  margin-bottom: 35px;
}
.home #home-partner a {
  margin-right: 35px;
  display: block;
}
@media screen and (min-width: 1200px) {
  .home #home-partner a {
    display: inline-block;
  }
}
.home #home-partner a button {
  margin-top: 0;
  float: none;
  display: inline-block;
  background: #ffffff;
}
.home #home-partner a.home-partner-link-contact {
  margin-bottom: 23px;
}
.home #home-partner .home-partner-text {
  padding-left: 50px;
}
.home #home-partner .home-partner-dots-box {
  position: relative;
}
.home #home-partner .home-partner-dots-box .animated-dots {
  display: none;
}
@media screen and (min-width: 1200px) {
  .home #home-partner .home-partner-dots-box .animated-dots {
    position: absolute;
    left: 45%;
    top: 15px;
    display: block;
    z-index: -1;
  }
}
.home #home-partner .home-partner-dots-box button:hover {
  background: black;
}
.home #home-3 {
  padding: 0;
}
.home .plakat {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}
.home .plakat h3 {
  margin-bottom: 50px;
}
@media (min-width: 970px) {
  .home .plakat .w80p {
    margin: 50px auto 0;
    width: 80%;
  }
}
.home .plakat img {
  display: block;
  margin: 30px auto 0;
  width: 70%;
}
.home .plakat a {
  font-weight: bold;
  color: #000000;
}
.home .plakat a:hover {
  color: #FCDC00;
}
@media (max-width: 1199px) {
  .home#home-process:before {
    content: none !important;
  }
  #home-header {
    background-size: cover !important;
  }
  .home-header-description {
    min-width: 650px;
  }
  .home #home-header .button-bottom {
    position: relative;
  }
}
@media (max-width: 991px) {
  .home-header-description {
    min-width: unset !important;
  }
  .home #home-header {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .home #home-partner .yellow-lines {
    display: none;
  }
  .home #home-partner .mobile {
    float: left;
  }
  .home #home-partner a {
    display: block;
  }
  .home #home-partner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home #home-header {
    margin: 0 -15px;
    padding-bottom: 30px;
    background-position-x: center !important;
  }
  .home #home-header h1 {
    font-size: 37px;
  }
}
@media (max-width: 500px) {
  .home #home-partner {
    padding-right: 0;
    padding-left: 0;
  }
}
#bim-header {
  padding-top: 30px;
  padding-bottom: 130px;
}
@media screen and (min-width: 768px) {
  #bim-header {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
#bim-header h1 {
  font-size: 20px;
  padding-bottom: 0;
  text-transform: capitalize;
}
@media screen and (min-width: 768px) {
  #bim-header h1 {
    font-size: 40px;
  }
}
#bim-header p.gray {
  color: #9B9B9B;
}
@media screen and (min-width: 768px) {
  #bim-header p.gray {
    font-size: 20px;
  }
}
@media screen and (min-width: 992px) {
  #bim-header p.gray {
    font-size: 35px;
  }
}
#bim-content {
  text-align: left;
  background: #f5f5f5;
  padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
  #bim-content {
    padding-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  #bim-content .img-bim-screen {
    margin-top: -130px;
  }
}
#bim-content .img-bim-building {
  margin-top: 50px;
}
#bim-content span {
  font-size: 18px;
}
#bim-content .third-row {
  margin-top: 50px;
}
#bim-content h3 {
  position: relative;
  font-weight: bold;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  #bim-content h3:before {
    content: url("./img/arrow-right-black.svg");
    /* FontAwesome Unicode */
    width: 19px;
    height: 19px;
    display: inline-block;
    margin-left: -40px;
    left: 0;
    position: absolute;
    top: 0;
  }
}
#bim-3 {
  padding-top: 50px;
  padding-bottom: 50px;
}
#bim-3 a {
  margin: auto auto;
  float: none;
  display: block;
}
#bim-3 button {
  margin: auto auto;
  float: none;
  margin-top: 30px;
}
.relative-bim {
  position: relative;
  min-height: 250px;
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .relative-bim {
    min-height: 380px;
  }
}
@media screen and (min-width: 992px) {
  .relative-bim {
    min-height: 450px;
  }
}
@media screen and (min-width: 1200px) {
  .relative-bim {
    margin-top: 50px;
    min-height: 600px;
  }
}
#careers {
  padding-bottom: 45px;
}
@media screen and (min-width: 768px) {
  #careers {
    padding-bottom: 150px;
  }
}
#careers #careers-header {
  padding-top: 30px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  #careers #careers-header {
    padding-top: 50px;
    padding-bottom: 75px;
  }
}
@media screen and (min-width: 1200px) {
  #careers #careers-header {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
#careers #careers-header .animated-dots {
  display: none;
  top: -80px;
}
@media screen and (min-width: 1200px) {
  #careers #careers-header .animated-dots {
    display: block;
  }
}
#careers #careers-header h1 {
  font-size: 40px;
  margin-bottom: 20px;
  padding-bottom: 0;
  position: relative;
  z-index: 10;
  background: #ffffff;
}
@media screen and (min-width: 1200px) {
  #careers #careers-header h1 {
    padding-left: 15px;
    margin-left: -15px;
    margin-bottom: 0;
  }
}
#careers #careers-header span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #careers #careers-header span {
    font-size: 22px;
    font-weight: 500;
  }
}
#careers #careers-header button {
  display: block;
  color: black;
  border: 2px solid black;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  margin-left: 0;
  float: none;
}
#careers #careers-header button:after {
  content: url("./img/arrow-down-black.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0;
  position: relative;
  transition: all 0.3s ease;
}
#careers #careers-header button:hover {
  border: 2px solid #242222;
  background: #242222;
  color: #FCDC00;
}
#careers #careers-header button:hover:after {
  content: url("./img/arrow-down-yellow.svg");
}
#careers #careers-section-2 .careers-section-2-img {
  margin-bottom: 50px;
}
@media screen and (min-width: 992px) {
  #careers #careers-section-2 .careers-section-2-description {
    padding-left: 80px;
    padding-right: 80px;
  }
}
#careers #careers-section-2 .careers-section-2-description h3 {
  font-size: 25px;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  #careers #careers-section-2 .careers-section-2-description h3 {
    font-size: 22px;
  }
}
#careers #careers-section-2 .careers-section-2-description span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 992px) {
  #careers #careers-section-2 .careers-section-2-description span {
    font-size: 22px;
  }
}
#careers #careers-typical {
  margin-top: 50px;
  padding: 0 20px;
  position: relative;
}
#careers #careers-typical h2 {
  margin: 0 -20px 0.5rem;
}
@media screen and (min-width: 992px) {
  #careers #careers-typical h2 {
    padding-bottom: 50px;
    margin-left: 50px;
  }
}
#careers #careers-typical h3 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 21px;
}
#careers #careers-typical span {
  font-size: 13px;
}
#careers #careers-typical img {
  max-width: 100%;
}
#careers #careers-typical .careers-typical-slider {
  position: relative;
}
#careers #careers-typical .typical-day-block {
  padding: 15px;
}
#careers #careers-typical .careers-typical-row {
  padding-left: 50px;
  padding-right: 50px;
}
#careers #careers-typical .careers-typical-row:before {
  position: absolute;
  content: url("./img/arrow-left-black.svg");
  /* FontAwesome Unicode */
  width: 19px;
  height: 19px;
  display: inline-block;
  left: 0;
  top: 55%;
  transform: translateY(-50%);
}
#careers #careers-typical .careers-typical-row:after {
  position: absolute;
  content: url("./img/arrow-right-black.svg");
  /* FontAwesome Unicode */
  width: 19px;
  height: 19px;
  display: inline-block;
  right: 0;
  top: 55%;
  transform: translateY(-50%);
}
#careers #careers-recruiter-process {
  margin: 50px -15px 0;
  background: #f5f5f5;
  padding: 30px 15px 0;
}
@media screen and (min-width: 786px) {
  #careers #careers-recruiter-process {
    margin: 50px 0 0;
    padding-top: 50px;
  }
}
#careers #careers-recruiter-process h2 {
  text-align: left;
  margin-left: 30px;
}
#careers #careers-recruiter-process .animated-dots {
  position: relative;
  margin: 80px auto;
  display: none;
}
@media screen and (min-width: 1200px) {
  #careers #careers-recruiter-process .animated-dots {
    display: block;
  }
}
#careers #careers-recruiter-process ol {
  text-align: left;
  list-style-type: none;
}
#careers #careers-recruiter-process ol li {
  font-size: 17px;
  margin-top: 15px;
  counter-increment: step-counter;
  position: relative;
}
#careers #careers-recruiter-process ol li:before {
  content: counter(step-counter);
  background: url("./img/process-yellow-block-number.svg");
  font-size: 45px;
  min-width: 56px;
  color: black;
  padding: 0px 15px;
  background-size: contain;
  margin-right: 23px;
  background-repeat: no-repeat;
  background-position-y: 40px;
  width: 56px !important;
  display: inline-block;
  text-align: center;
  top: 0;
  position: relative;
}
#careers #careers-recruiter-process .careers-recruiter-content .careers-recruiter-line {
  width: 80%;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#careers #careers-jobs {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  #careers #careers-jobs {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#careers #careers-jobs h2 {
  padding-bottom: 50px;
}
#careers #careers-jobs .career-job-descritpion {
  display: none;
  padding: 30px 15px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  height: 0;
}
#careers #careers-jobs .career-job-descritpion.activated {
  height: auto;
  display: block;
  opacity: 1;
}
#careers #careers-jobs .career-job {
  border-top: 1px solid #ecebeb;
  padding: 10px 15px;
}
@media screen and (min-width: 1200px) {
  #careers #careers-jobs .career-job {
    padding: 15px 15px;
  }
}
#careers #careers-jobs .career-job:last-child {
  border-bottom: 1px solid #ecebeb;
}
#careers #careers-jobs .career-job span {
  font-size: 18px;
}
#careers #careers-jobs .career-job span.career-job-name {
  font-weight: bold;
}
#careers #careers-jobs .career-job .career-job-city {
  display: block;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  #careers #careers-jobs .career-job .career-job-city {
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  #careers #careers-jobs .career-job .career-job-city {
    display: inline-block;
    width: 50%;
  }
}
#careers #careers-jobs .career-job .career-job-city:before {
  content: url("./img/careers-jobs-place.svg");
  /* FontAwesome Unicode */
  width: 25px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
#careers #careers-jobs .career-job .career-job-type {
  display: block;
  margin-top: 10px;
}
@media screen and (min-width: 992px) {
  #careers #careers-jobs .career-job .career-job-type {
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  #careers #careers-jobs .career-job .career-job-type {
    display: inline-block;
    width: 50%;
  }
}
#careers #careers-jobs .career-job .career-job-type:before {
  content: url("./img/careers-jobs-type.svg");
  /* FontAwesome Unicode */
  width: 27px;
  height: 28px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}
#careers #careers-jobs .career-job button {
  float: right;
  background: white;
  position: relative;
  color: black;
  border: 3px solid black;
  font-size: 18px;
  transition: all 0.3s ease;
  padding: 3px 21px 5px 10px;
  margin: 10px 0 0;
}
@media screen and (min-width: 992px) {
  #careers #careers-jobs .career-job button {
    margin: 0;
  }
}
#careers #careers-jobs .career-job button span {
  font-size: 15px;
}
#careers #careers-jobs .career-job button:hover {
  background: black;
}
#careers #careers-jobs .career-job button:hover span {
  color: #FCDC00;
}
#careers .job-apply-btn {
  float: none;
  background: white;
  position: relative;
  color: black;
  border: 3px solid black;
  padding: 5px;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 21px;
  transition: all 0.3s ease;
  padding-top: 3px;
  margin: 0;
  display: block;
  width: intrinsic;
  /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;
  /* Firefox/Gecko */
  width: -webkit-max-content;
  /* Chrome */
}
#careers .job-apply-btn:hover {
  background: black;
  cursor: pointer;
  color: #FCDC00;
}
#careers .slick-prev,
#careers .slick-next {
  top: 40% !important;
}
@media (max-width: 1199px) {
  .careers-recruiter-content {
    min-width: 500px;
  }
}
@media (max-width: 991px) {
  .careers-recruiter-content {
    padding-bottom: 30px;
    min-width: unset !important;
  }
}
@media (max-width: 767px) {
  #careers #careers-recruiter-process ol {
    padding: 0;
  }
}
@media (max-width: 500px) {
  #careers .careers-yellow-dots {
    left: 15px !important;
  }
}
#contact #contact-header {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  #contact #contact-header {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 1200px) {
  #contact #contact-header {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}
#contact #contact-header .contact-headline {
  position: relative;
}
#contact #contact-header .contact-headline .animated-dots {
  display: none;
  top: -80px;
}
@media screen and (min-width: 1200px) {
  #contact #contact-header .contact-headline .animated-dots {
    display: block;
  }
}
#contact #contact-header .contact-headline h1 {
  font-size: 40px;
  margin-bottom: 20px;
  padding-bottom: 0;
  position: relative;
  z-index: 10;
  background: #ffffff;
}
@media screen and (min-width: 1200px) {
  #contact #contact-header .contact-headline h1 {
    padding-left: 15px;
    margin-left: -15px;
    margin-bottom: 0;
  }
}
#contact #contact-header .second-row {
  margin-top: 50px;
}
#contact #contact-header .second-row h2 {
  position: relative;
  font-size: 40px;
  z-index: 3;
}
#contact #contact-header .second-row h2:before {
  content: ' ';
  background: url("./img/contact-place-icon.svg") no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 56px;
  height: 70px;
  top: 50%;
  left: -45px;
  z-index: -1;
  transform: translateY(-50%);
}
@media screen and (min-width: 1200px) {
  #contact #contact-header .second-row h2:before {
    width: 75px;
    height: 94px;
    left: -60px;
  }
}
#contact #contact-header .second-row h3 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 30px;
}
#contact #contact-header .second-row span {
  font-size: 16px;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  #contact #contact-header .second-row span {
    font-size: 17px;
  }
}
#contact #contact-header .second-row .bratislava {
  padding-left: 45px;
}
@media screen and (min-width: 768px) {
  #contact #contact-header .second-row .bratislava {
    margin-top: 75px;
  }
}
#contact #contact-header .second-row .praha {
  padding-left: 45px;
  margin-top: 75px;
}
@media screen and (min-width: 768px) {
  #contact #contact-header .second-row .praha {
    margin-top: 0;
  }
}
#contact #contact-form {
  background: #9B9B9B;
  padding: 50px 100px;
  margin: 0 -15px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  #contact #contact-form {
    margin: 0;
  }
}
#contact #contact-form h3 {
  color: white;
  font-size: 40px;
}
#contact #contact-form .contact-form-success {
  padding: 50px 30px;
  color: #ffffff;
}
#contact #contact-form .contact-form-success p {
  margin-bottom: 0;
}
#contact #contact-form .form-holder {
  text-align: left;
  margin: auto auto;
  position: relative;
}
#contact #contact-form .form-holder .yellow-lines {
  position: absolute;
  max-height: 465px;
  top: 24px;
  left: -120px;
}
#contact #contact-form .form-holder .input-box {
  position: relative;
  margin-bottom: 30px;
}
#contact #contact-form .form-holder .input-box:last-child {
  margin-bottom: 0;
}
#contact #contact-form .form-holder .input-box.with-error input {
  color: red;
}
#contact #contact-form .form-holder .input-box span.invalid-message {
  position: absolute;
  right: 7px;
  font-size: 12px;
  color: red !important;
  z-index: 9999;
  top: 50%;
  transform: translateY(-50%);
}
#contact #contact-form .form-holder .checkbox-box.with-error span {
  color: red !important;
}
#contact #contact-form .form-holder textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border: none;
  font-size: 25px;
  height: 137px;
  min-height: 137px;
  max-height: 137px;
}
#contact #contact-form .form-holder input {
  width: 100%;
  border: none;
  font-size: 25px;
}
#contact #contact-form .form-holder span {
  font-size: 15px;
  color: white;
}
#contact #contact-form .form-holder .yellow {
  color: #FCDC00;
}
#contact #contact-form .form-holder .gdpr-check {
  width: 23px;
  top: -1px;
  position: relative;
}
#contact #contact-persons {
  padding-top: 50px;
  padding-bottom: 50px;
}
#contact #contact-persons h3 {
  font-size: 40px;
  font-weight: 400;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  #contact #contact-persons h3 {
    margin-top: 40px;
  }
}
#contact #contact-persons span {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  #contact #contact-persons span {
    font-size: 17px;
  }
}
#contact #contact-persons .second {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  #contact #contact-persons .second {
    margin-top: 100px;
  }
}
#contact #contact-map {
  position: relative;
  overflow-x: hidden;
}
@media screen and (max-width: 767px) {
  #contact #contact-map {
    padding-bottom: 200px;
    margin: 0 -15px;
  }
}
@media screen and (max-width: 767px) {
  #contact #contact-map img {
    max-width: none;
    width: 180%;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 200px;
    transform: translate(-50%, -50%);
  }
}
#contact-2 {
  background: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 991px) {
  #contact #contact-form .form-holder .yellow-lines {
    top: 22px;
    left: -103px;
  }
}
@media (max-width: 767px) {
  #contact #contact-form .form-holder .yellow-lines {
    display: none;
  }
}
@media (max-width: 575px) {
  #contact #contact-form {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 401px) {
  #contact #contact-header .contact-yellow-dots {
    max-width: 80%;
  }
}
footer {
  padding: 50px 30px 30px;
  background: #262424;
  color: white;
  text-align: left;
}
@media screen and (min-width: 768px) {
  footer {
    padding: 50px 0 50px;
  }
}
footer #cookie-lista {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #FCDC00;
  min-height: 67px;
  padding-top: 17px;
  padding-bottom: 17px;
  z-index: 999;
}
footer #cookie-lista span {
  color: black;
}
footer #cookie-lista button {
  background: black;
  color: white;
  border: none !important;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 15px;
}
footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 25px;
}
footer ul li {
  font-size: 18px;
  margin-top: 10px;
}
footer ul.list--we-are-hiring {
  margin-top: 0;
}
footer ul.list--we-are-hiring li {
  display: inline;
  font-size: 16px;
  margin-top: 0;
}
footer ul.list--we-are-hiring li:before {
  content: "  \2022  ";
  font-size: 22px;
  line-height: 18px;
}
footer h2 {
  font-size: 24px;
  color: #fcdc00;
  font-weight: bold;
}
footer .footer-section-headline {
  padding-bottom: 30px;
}
footer .follow-us img {
  width: 40px;
  max-height: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
}
footer .footer-block--hiring span {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  footer .footer-block--hiring span {
    font-size: 17px;
  }
}
footer .footer-block--hiring .opened-positions {
  transition: all 0.3s ease;
  margin-top: 30px;
}
footer .footer-block--hiring .opened-positions div {
  border-bottom: 2px solid white;
  padding-bottom: 10px;
  font-size: 21px;
  font-weight: 300;
}
footer .footer-block--hiring .opened-positions div img {
  margin-left: 30px;
  top: -2px;
  position: relative;
  transition: all 0.3s ease;
}
footer .footer-block--hiring .opened-positions:hover img {
  transform: translateX(10px);
}
footer .privacy-policy {
  margin-top: 30px;
}
footer .privacy-policy li {
  font-size: 12px;
  display: inline-block;
  color: #9B9B9B;
}
footer .privacy-policy li:not(:first-child) {
  margin-left: 15px;
}
footer .follow-us-mobile {
  display: none;
}
footer .opened-positions-mobile {
  display: none;
}
footer #hf_logo {
  max-width: 200px;
  margin-top: 20px;
}
@media (max-width: 991px) {
  footer .privacy-policy .opened-positions div {
    width: 100%;
  }
  footer .opened-positions span {
    width: 75%;
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .footer-block:not(:last-child) {
    width: 50%;
  }
  .footer-block:last-child {
    margin-top: 30px;
  }
  footer .privacy-policy .opened-positions {
    transition: all 0.3s ease;
    top: -60px;
  }
  footer .privacy-policy .inner-privacy {
    top: 50px;
  }
}
@media (max-width: 575px) {
  .footer-menu {
    display: none;
  }
  footer .follow-us {
    display: none;
  }
  footer .opened-positions {
    display: none;
  }
  footer .follow-us-mobile {
    display: block;
  }
  footer .opened-positions-mobile {
    display: block;
  }
  footer .follow-us-mobile ul li {
    float: left;
  }
  footer .follow-us-mobile ul li:not(:first-child) {
    margin-left: 25px;
  }
  .footer-block {
    width: 100% !important;
  }
  footer h2,
  footer ul {
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
  footer .footer-block span {
    display: block;
  }
  footer .privacy-policy {
    padding-bottom: 0;
  }
  footer .privacy-policy .inner-privacy {
    top: 0;
  }
}
@media (max-width: 500px) {
  .footer-block:not(:last-child) {
    width: 100%;
    margin-top: 30px;
  }
  footer .footer-section-headline {
    padding-bottom: 0;
  }
}
@media (max-width: 460px) {
  footer .privacy-policy li {
    margin-left: 0 !important;
    margin-right: 15px;
  }
}
.header {
  border-bottom: 1px solid lightgray;
  min-height: 85px;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 99;
}
.header .header-container {
  width: 100%;
  height: 64px;
}
.content {
  padding-top: 85px;
}
@media screen and (min-width: 992px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
    margin: 0 -15px;
  }
}
.language-switcher {
  position: fixed;
  top: 27px;
  right: 27px;
  height: 55px;
}
@media screen and (min-width: 992px) {
  .language-switcher {
    top: 26px;
    right: 15px;
  }
}
.language-switcher a {
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.35);
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 19px;
  font-weight: 500;
}
.language-switcher a:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
}
.language-switcher img {
  margin-left: 5px;
  margin-top: -5px;
}
.language-switcher span {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.35);
}
.language-switcher span:hover {
  color: #000000;
  cursor: pointer;
}
.language-switcher span:hover .dropdown-language {
  display: block;
  opacity: 1;
}
.language-switcher span.inactive:hover {
  color: rgba(0, 0, 0, 0.35);
  cursor: default;
}
.language-switcher .dropdown-language {
  display: none;
  position: absolute;
  transition: all 0.3s ease;
  opacity: 0;
  top: 54px;
  width: 70px;
  height: 75px;
  color: black;
  background: white;
  padding-top: 10px;
  left: -8px;
  font-weight: 500;
  border: 1px solid lightgray;
  border-top: 0;
}
.language-switcher .dropdown-language ul {
  list-style-type: none;
  padding: 0;
}
.language-switcher .dropdown-language li {
  color: black;
}
.language-switcher .dropdown-language li a {
  color: black;
}
.language-switcher:hover {
  cursor: default;
}
.language-switcher:hover .dropdown-language {
  display: block;
  opacity: 1;
}
.navbar-toggler {
  position: relative;
  top: 10px;
  border: 1px solid black;
  padding: 0rem 0.45rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 45px;
  height: 33px;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 1.7em;
  height: 1.7em;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-brand {
  width: 195px;
  margin-top: 18px;
}
.main-menu-ul {
  list-style-type: none;
  margin-top: 14px;
  float: right;
  position: absolute;
  right: 0;
  top: 15px;
  padding: 0;
}
.main-menu-ul li {
  float: left;
  padding: 0 30px;
  font-size: 19px;
  transition: all 0.3s ease;
}
@media screen and (min-width: 992px) {
  .main-menu-ul li {
    padding: 0 10px;
  }
  .main-menu-ul li:last-child {
    padding-right: 0;
  }
}
@media screen and (min-width: 1200px) {
  .main-menu-ul li {
    padding: 0 16px;
  }
}
.main-menu-ul li:hover {
  cursor: pointer;
}
.main-menu-ul li:last-child {
  margin-right: 0;
}
.main-menu-ul li a {
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.35);
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-shadow: unset !important;
}
.main-menu-ul li a:hover {
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
.main-menu-ul li.active a {
  color: #000000;
}
.opacity-1 {
  transition: all 0.3s ease;
  opacity: 1 !important;
}
@media (max-width: 1500px) {
  .main-menu-ul {
    right: 130px;
  }
}
@media (max-width: 1199px) {
  .main-menu-ul {
    right: 75px;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 87px;
    opacity: 0;
    background: white !important;
    height: 70px;
    position: fixed;
    left: 0;
    border-bottom: 1px solid lightgray;
  }
  .language-switcher {
    display: none;
    top: 102px !important;
    position: absolute;
    right: 27px;
  }
  .main-menu-ul {
    width: 650px;
    position: relative !important;
    float: none !important;
    margin: auto auto;
    margin-top: 5px !important;
  }
  .language-switcher .dropdown-language {
    top: 45px;
  }
  .navbar-expand-lg .navbar-toggler {
    display: block;
    position: fixed;
    right: 10%;
    top: 26px;
  }
}
@media (max-width: 991px) {
  .main-menu-ul li {
    float: none;
    padding: 0 30px;
    font-size: 19px;
    transition: all 0.3s ease;
    margin-top: 5px;
  }
  .main-menu-ul {
    right: 35px;
  }
  .navbar-collapse {
    margin-top: 167px;
    height: 230px;
    position: fixed;
    left: 0;
  }
}
@media (max-width: 900px) {
  .main-menu-ul {
    width: 100%;
  }
  .main-menu-ul li {
    font-size: 19px;
    display: block;
    width: 100%;
    float: none;
    margin: auto auto !important;
    margin-top: 10px !important;
  }
  .navbar-collapse {
    margin-top: 187px;
    height: 270px;
  }
  .main-menu-ul li:last-child {
    margin: auto auto !important;
    margin-top: 10px !important;
  }
  .navbar-collapse {
    padding-left: 9%;
  }
}
@media (max-width: 400px) {
  .menu-logo {
    max-width: 165px !important;
  }
  .navbar-collapse {
    margin-top: 190px;
  }
  .navbar-expand-lg .navbar-toggler {
    top: 24px;
  }
}
body {
  font-family: 'Poppins', sans-serif;
  background: white;
}
.menu-logo {
  max-width: 220px;
  float: left;
}
.h1,
h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 80px;
}
.h2,
h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 55px;
  font-weight: 400;
}
span {
  font-size: 21px;
  font-weight: 300;
}
button:focus,
button:active {
  outline: none !important;
}
img {
  max-width: 100%;
}
.loader-people {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: #FCDC00;
  text-decoration: none !important;
}
.loaderMain {
  position: absolute;
  width: 100%;
  min-height: 300px;
  padding-top: 50px;
  text-align: center;
}
.loaderMain img {
  float: none;
  margin: auto auto;
  margin-top: 50px;
}
.infinityLoading {
  float: none;
  margin: auto auto;
  margin-top: 50px;
  max-width: 150px;
}
#preload {
  display: none;
}
.content {
  padding-top: 85px;
}
.website-h1-cz {
  padding-left: 0px !important;
}
.border-right {
  border-right: 3px solid black !important;
}
.border-bottom {
  border-bottom: 3px solid black !important;
}
h1 {
  padding-bottom: 30px;
  font-weight: 400;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
@-webkit-keyframes fadeInWebsite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeInWebsite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInWebsite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in-website {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeInWebsite ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeInWebsite ease-in 1;
  animation: fadeInWebsite ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.arrow-right-white {
  display: block;
  color: white;
  border: 2px solid white;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  float: left;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .arrow-right-white {
    font-size: 13px;
  }
}
.arrow-right-white:after {
  content: url("./img/arrow-right-white.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0;
  position: relative;
  transition: all 0.3s ease;
  vertical-align: middle;
}
.arrow-right-white:hover {
  border: 2px solid #242222;
  background: #242222;
  color: #FCDC00;
}
.arrow-right-white:hover:after {
  content: url("./img/arrow-right-yellow.svg");
}
.arrow-down-white {
  display: block;
  color: white;
  border: 2px solid white;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  float: left;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .arrow-down-white {
    font-size: 13px;
  }
}
.arrow-down-white:after {
  content: url("./img/arrow-down-white.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0;
  position: relative;
  transition: all 0.3s ease;
  vertical-align: middle;
}
.arrow-down-white:hover {
  border: 2px solid #242222;
  background: #242222;
  color: #FCDC00;
}
.arrow-down-white:hover:after {
  content: url("./img/arrow-down-yellow.svg");
}
.arrow-right-black {
  display: block;
  color: black;
  border: 2px solid black;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  float: left;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .arrow-right-black {
    font-size: 13px;
  }
}
.arrow-right-black:after {
  content: url("./img/arrow-right-black.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0;
  position: relative;
  transition: all 0.3s ease;
  vertical-align: middle;
}
.arrow-right-black:hover {
  border: 2px solid #242222;
  transition: all 0.3s ease;
  background: #242222;
  color: #FCDC00;
}
.arrow-right-black:hover:after {
  transition: all 0.3s ease;
  content: url("./img/arrow-right-yellow.svg");
}
.slick-next:before {
  position: relative;
  transition: all 0.3s ease;
  content: url("./img/arrow-right-black.svg") !important;
}
.slick-next {
  transition: all 0.3s ease;
  margin-top: -32px;
}
.slick-next:hover {
  transform: translate(10px, -50%);
}
.slick-prev:before {
  position: relative;
  transition: all 0.3s ease;
  content: url("./img/arrow-left-black-2.svg") !important;
}
.slick-prev {
  transition: all 0.3s ease;
  margin-top: -32px;
}
.slick-prev:hover {
  transform: translate(-10px, -50%);
}
.arrow-top-black {
  display: block;
  color: white;
  border: 2px solid black;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  float: left;
  font-size: 21px;
}
.arrow-top-black:after {
  content: url("./img/arrow-top-black.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0px;
  position: relative;
  top: 4px;
  transition: all 0.3s ease;
}
.arrow-top-black:hover {
  border: 2px solid #242222;
  background: #242222;
  color: #FCDC00;
}
.arrow-top-black:hover:after {
  content: url("./img/arrow-top-yellow.svg");
}
.arrow-down-black {
  display: block;
  color: white;
  border: 2px solid black;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  float: left;
  font-size: 21px;
}
.arrow-down-black:after {
  content: url("./img/arrow-down-black.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0px;
  position: relative;
  top: 4px;
  transition: all 0.3s ease;
}
.arrow-down-black:hover {
  border: 2px solid #242222;
  background: #242222;
  color: #FCDC00;
}
.arrow-down-black:hover:after {
  content: url("./img/arrow-down-yellow.svg");
}
.yellow-box-headline h1 {
  width: 100%;
  margin-top: 0;
  background: white;
  position: relative;
  padding: 0;
}
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 68px;
  }
}
@media (max-width: 991px) {
  .h2,
  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 400;
  }
  span {
    font-size: 18px;
    font-weight: 300;
  }
}
@media (max-width: 500px) {
  .h1,
  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
  }
}
#people-header {
  background: #FCDC00;
  padding-top: 30px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  #people-header {
    padding-top: 50px;
    padding-bottom: 75px;
  }
}
@media screen and (min-width: 1200px) {
  #people-header {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
#people-header .animated-dots {
  display: none;
  top: -80px;
}
@media screen and (min-width: 1200px) {
  #people-header .animated-dots {
    display: block;
  }
}
#people-header h1 {
  font-size: 40px;
  margin-bottom: 20px;
  padding-bottom: 0;
  position: relative;
  z-index: 10;
  background: #FCDC00;
}
@media screen and (min-width: 1200px) {
  #people-header h1 {
    padding-left: 15px;
    margin-left: -15px;
    margin-bottom: 0;
  }
}
#people-header span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #people-header span {
    font-size: 22px;
    font-weight: 500;
  }
}
#people-header button {
  display: block;
  color: black;
  border: 2px solid black;
  margin: auto auto;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s ease;
  float: left;
}
#people-header button:after {
  content: url("./img/arrow-down-black.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0px;
  position: relative;
  top: 4px;
  transition: all 0.3s ease;
}
#people-header button:hover {
  border: 2px solid #242222;
  background: #242222;
  color: #FCDC00;
}
#people-header button:hover:after {
  content: url("./img/arrow-down-yellow.svg");
}
#people-partners {
  padding-bottom: 50px;
  padding-top: 50px;
}
#people-partners .people-partners-row {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  margin-right: 10px;
}
#people-partners .people-partners-row .people-partners-block {
  padding: 15px;
  position: relative;
  transition: all 0.3s ease;
}
#people-partners .people-partners-row .people-partners-block .black-overlay {
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  color: #fff;
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  background-clip: content-box;
  padding-bottom: 15px;
}
#people-partners .people-partners-row .people-partners-block .black-overlay .overlay-inner {
  padding: 30px;
  margin-top: 45px;
}
#people-partners .people-partners-row .people-partners-block .black-overlay h4 {
  opacity: 0;
  font-size: 35px;
  font-weight: 400;
  transition: all 0.5s ease;
}
#people-partners .people-partners-row .people-partners-block .black-overlay span {
  transition: all 0.5s ease;
  opacity: 0;
  font-size: 16px;
  font-weight: 400;
}
#people-partners .people-partners-row .people-partners-block:hover {
  cursor: pointer;
  transform: scale(1.1);
  z-index: 2;
}
#people-partners .people-partners-row .people-partners-block:hover .black-overlay {
  opacity: 1;
}
#people-partners .people-partners-row .people-partners-block:hover .black-overlay h4,
#people-partners .people-partners-row .people-partners-block:hover .black-overlay span {
  opacity: 1;
}
#people-asociated-partners {
  background: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
}
#people-asociated-partners h2 {
  text-align: left;
}
#people-asociated-partners .people-asociated-partner {
  margin-top: 30px;
  position: relative;
}
#people-asociated-partners .people-asociated-partner .associate-language-badge {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 30px;
  top: 15px;
  background: #FCDC00;
  border-radius: 50%;
  padding-top: 6px;
  text-align: center;
}
#people-asociates {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
}
#people-asociates span {
  font-size: 22px;
  color: #9B9B9B;
}
@media screen and (min-width: 768px) {
  #people-asociates span {
    font-size: 30px;
  }
}
@media screen and (min-width: 992px) {
  #people-asociates span {
    font-size: 40px;
  }
}
#people-5 {
  background: #f5f5f5;
}
#people-detail-header {
  position: relative;
  background: #FCDC00;
  padding-top: 30px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  #people-detail-header {
    padding-top: 50px;
    padding-bottom: 75px;
  }
}
@media screen and (min-width: 1200px) {
  #people-detail-header {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
#people-detail-header .loader-people,
#people-detail-header .loaderPeople {
  padding-top: 200px;
  padding-bottom: 200px;
}
#people-detail-header .loader-people img,
#people-detail-header .loaderPeople img {
  margin-top: 200px;
  margin-bottom: 200px;
}
#people-detail-header .animated-dots {
  display: none;
  top: -80px;
}
@media screen and (min-width: 1200px) {
  #people-detail-header .animated-dots {
    display: block;
  }
}
#people-detail-header h1 {
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 0;
  position: relative;
  z-index: 10;
  background: #FCDC00;
}
@media screen and (min-width: 1200px) {
  #people-detail-header h1 {
    padding-left: 15px;
    margin-left: -15px;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  #people-detail-header .back-btn-wrap {
    position: absolute;
    top: 15px;
  }
}
#people-detail-header .back-btn-wrap button {
  background: transparent;
  border: none;
}
#people-detail-header .back-btn-wrap button img {
  margin-right: 10px;
  max-height: 16px;
  vertical-align: middle;
}
#people-detail-header .back-btn-wrap button span {
  font-size: 12px;
  font-weight: bold;
}
#people-detail-header .back-btn-wrap button:hover span {
  text-decoration: underline;
}
#people-detail-header .people-detail-thumb {
  margin-top: 40px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1200px) {
  #people-detail-header .people-detail-thumb {
    margin-top: 175px;
  }
}
#people-detail-header .people-detail-description h2 {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  #people-detail-header .people-detail-description h2 {
    font-size: 32px;
  }
}
#people-detail-header .people-detail-description span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #people-detail-header .people-detail-description span {
    font-size: 17px;
  }
}
#people-detail-2 {
  padding-top: 50px;
  padding-bottom: 50px;
}
#people-detail-2 #people-detail-projects {
  text-align: left;
}
#people-detail-2 #people-detail-projects h2 {
  text-align: left;
}
#people-detail-2 #people-detail-projects span {
  font-size: 54px;
  color: #9B9B9B;
}
#people-detail-skills img {
  height: 130px;
  margin-bottom: 15px;
}
#people-detail-skills .skill {
  float: none;
  margin: auto auto;
  text-align: center;
}
#people-detail-3 {
  background: #f5f5f5;
}
@media (max-width: 991px) {
  #people-detail #people-detail-header .people-detail-headline h1 {
    margin-top: 10px;
    width: 120%;
  }
}
@media (max-width: 767px) {
  #people-detail #people-detail-header .people-detail-headline h1 {
    margin-top: 10px;
    width: 100%;
    font-size: 60px;
  }
  #people-detail .people-detail-thumb {
    margin-left: 0;
  }
}
@media (max-width: 500px) {
  #people-detail #people-detail-header .people-detail-headline h1 {
    margin-top: 25px;
    width: 100%;
    font-size: 46px;
    margin-left: 0;
  }
  #people-detail-2 #people-detail-projects span {
    font-size: 38px;
    color: #9B9B9B;
  }
  #people-detail #people-detail-header .people-detail-white-dots {
    left: 15px;
    max-width: 80%;
  }
}
@media (max-width: 380px) {
  #people-detail #people-detail-header .people-detail-headline h1 {
    margin-top: 5px;
    width: 100%;
    font-size: 33px;
    margin-left: 0;
  }
}
@media (max-width: 320px) {
  #people-detail #people-detail-header .people-detail-headline h1 {
    margin-top: -10px;
  }
}
#projects {
  text-align: left;
  overflow-x: hidden;
}
#projects #projects-header {
  position: relative;
  padding-top: 80px;
}
#projects #projects-header h1 {
  font-size: 40px;
  padding-bottom: 40px;
  padding-left: 15px;
}
@media screen and (min-width: 768px) {
  #projects #projects-header h1 {
    font-size: 60px;
    padding-bottom: 60px;
    padding-left: 0;
  }
}
@media screen and (min-width: 992px) {
  #projects #projects-header h1 {
    font-size: 80px;
    padding-bottom: 80px;
  }
}
#projects #projects-header .gray-background {
  position: absolute;
  background: #f5f5f5;
  width: 120%;
  left: 0;
  height: 120%;
  top: 65px;
}
@media screen and (min-width: 768px) {
  #projects #projects-header .gray-background {
    width: 85%;
    left: auto;
    right: -15px;
    top: 0;
  }
}
#projects .projects-filters {
  padding-left: 75px;
  display: none;
}
@media screen and (min-width: 768px) {
  #projects .projects-filters {
    display: block;
  }
}
#projects .projects-filters ul {
  padding: 0;
  margin-top: 20px;
  list-style-type: none;
}
#projects .projects-filters ul input {
  margin-right: 10px;
  position: relative;
  top: 3px;
}
#projects .projects-filters ul li {
  margin-top: 5px;
}
#projects .projects-filters .expertise li {
  width: 50%;
  display: inline-block;
}
#projects .projects-filters .filter-image {
  box-sizing: border-box;
  position: relative;
}
#projects .projects-filters .filter-image h3 {
  font-size: 25px;
  font-weight: 700;
}
#projects .projects-filters .filter-image:before {
  background: url(./img/filter-icon.svg);
  content: "";
  position: absolute;
  left: -41px;
  top: 13px;
  width: 25px;
  height: 25px;
  background-size: cover;
  background-repeat: no-repeat;
}
#projects .projects-filters .projects-filter-block {
  padding: 0;
}
#projects .projects-filters .projects-filter-block h3 {
  font-size: 21px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}
#projects .projects-filters .projects-filter-block li {
  font-size: 13px;
}
#projects .projects-filters .projects-filter-block-headline {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 3px solid black;
}
#projects #projects-content {
  z-index: 2;
  position: relative;
  margin-top: 40px;
}
#projects #projects-content .left-column,
#projects #projects-content .right-column {
  margin-bottom: 45px;
}
@media screen and (min-width: 768px) {
  #projects #projects-content .left-column,
  #projects #projects-content .right-column {
    display: inline-block;
    width: 50%;
    margin-bottom: 0;
  }
  #projects #projects-content .left-column {
    padding-right: 30px;
  }
  #projects #projects-content .right-column {
    padding-left: 30px;
    margin-top: 100px;
  }
}
#projects #projects-content h3 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 21px;
  display: block;
}
#projects #projects-content span {
  display: block;
  font-size: 14px;
}
#projects #projects-content a.project-link-details {
  color: #000000;
  padding: 0 30px;
  display: block;
}
@media screen and (min-width: 768px) {
  #projects #projects-content a.project-link-details {
    padding: 0;
  }
}
#projects #projects-content .project-content-block {
  position: relative;
  overflow: hidden;
}
#projects #projects-content .project-content-block img {
  transition: all 0.5s ease-out !important;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
#projects #projects-content .project-content-block .project-overlay-hover {
  transition: all 0.5s ease-out !important;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  text-align: center;
  top: 0;
  left: 0;
}
#projects #projects-content .project-content-block .project-overlay-hover p {
  transition: all 0.3s ease !important;
  text-decoration: underline;
  font-size: 21px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #FCDC00;
}
#projects #projects-content .project-content-block:hover .project-overlay-hover {
  opacity: 1;
}
#projects #projects-content .project-content-block:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
#projects #projects-content .project-text-content-block {
  margin-bottom: 75px;
}
#projects #projects-load-more {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
#projects #projects-load-more button {
  color: black;
}
#projects #projects-load-more button:hover {
  color: #FCDC00 !important;
}
#projects input[type='checkbox']:checked {
  background: #fcdc00 !important;
  background-size: 100% !important;
}
#projects input[type='checkbox'] {
  border-radius: 0px;
  width: 7px;
  height: 6px;
  box-shadow: none;
  margin-left: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid black;
  padding: 7px;
  background: transparent !important;
}
#projects input[type='checkbox']:active,
#projects input[type='checkbox']:focus {
  outline: none;
}
@media (max-width: 991px) {
  .projects-filter-block {
    margin-top: 30px;
  }
}
@media (max-width: 500px) {
  #projects .projects-filters .expertise li {
    width: 100%;
  }
}
#projects-detail #project-detail-header {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 378px;
  margin: 0 -15px;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-header {
    margin: 0;
  }
}
#projects-detail #project-detail-headline {
  padding: 0 15px;
}
#projects-detail #project-detail-headline .center-headline {
  margin: auto auto;
  float: none;
}
#projects-detail #project-detail-headline h2 {
  font-weight: 400;
  margin-top: 30px;
}
#projects-detail #project-detail-headline span {
  font-size: 18px;
  font-weight: 300;
}
#projects-detail #project-detail-headline .bottom-line {
  border-bottom: 2px solid black;
}
#projects-detail #project-detail-headline .bottom-line .right span {
  float: right;
}
#projects-detail #project-detail-headline .first-row {
  padding-top: 30px;
  position: relative;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-headline .first-row {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 992px) {
  #projects-detail #project-detail-headline .first-row {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
#projects-detail #project-detail-headline .first-row .yellow-lines {
  display: none;
}
@media screen and (min-width: 992px) {
  #projects-detail #project-detail-headline .first-row .yellow-lines {
    display: block;
    top: -148px;
    left: 36px;
    position: absolute;
  }
}
#projects-detail #project-detail-headline .first-row .country-container,
#projects-detail #project-detail-headline .first-row .location-container {
  margin-bottom: 7px;
}
#projects-detail #project-detail-headline .first-row .country-container strong,
#projects-detail #project-detail-headline .first-row .location-container strong {
  margin-right: 10px;
}
#projects-detail #project-detail-headline .first-row .completion-container {
  margin-bottom: 25px;
}
#projects-detail #project-detail-headline .first-row .completion-container strong {
  margin-right: 10px;
}
#projects-detail #project-detail-headline .second-row {
  margin-top: 20px;
}
#projects-detail #project-detail-headline .second-row img#project_detail_main_image {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-headline .second-row .project-detail-description-container {
    padding-left: 16.666667%;
  }
  #projects-detail #project-detail-headline .second-row img#project_detail_main_image {
    float: left;
    max-width: 58.333333%;
    margin-right: 50px;
    margin-bottom: 50px;
    margin-left: calc(-16.666667% - 15px);
  }
  #projects-detail #project-detail-headline .second-row .project-detail-description {
    display: inline;
  }
}
#projects-detail #project-detail-facts {
  padding: 30px 15px;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-facts {
    padding: 50px 15px;
  }
}
#projects-detail #project-detail-facts h2 {
  margin-bottom: 20px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-facts h2 {
    margin-bottom: 50px;
  }
}
#projects-detail #project-detail-facts .gray {
  background: #F5F5F5;
}
#projects-detail #project-detail-facts .gray ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#projects-detail #project-detail-facts .gray li {
  padding: 15px 0;
}
#projects-detail #project-detail-facts .gray li:not(:last-child) {
  border-bottom: 2px solid #F5F5F5;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-facts .border-bottom-gray {
    padding: 0;
  }
}
#projects-detail #project-detail-facts .border-bottom-gray ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#projects-detail #project-detail-facts .border-bottom-gray li {
  padding: 15px 0;
  border-bottom: 2px solid #F5F5F5;
}
@media screen and (max-width: 767px) {
  #projects-detail #project-detail-facts .border-bottom-gray li:last-child {
    border-bottom: none;
  }
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-facts .border-bottom-gray li {
    padding: 15px 30px 15px;
  }
  #projects-detail #project-detail-facts .border-bottom-gray li:first-child {
    border-top: 2px solid #F5F5F5;
  }
}
#projects-detail #project-detail-facts .border-bottom-gray li strong {
  display: block;
  margin-bottom: 7px;
}
#projects-detail #project-detail-images {
  margin: 0 -15px;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-images {
    margin: 0;
  }
}
#projects-detail #project-detail-images .project-detail-image-box {
  margin-bottom: 50px;
  position: relative;
}
#projects-detail #project-detail-images .yellow-dots {
  position: absolute;
  bottom: -160px;
  right: -100px;
  display: none;
}
@media screen and (min-width: 1200px) {
  #projects-detail #project-detail-images .yellow-dots {
    display: block;
  }
}
#projects-detail #project-detail-more-projects {
  padding-bottom: 50px;
}
#projects-detail #project-detail-more-projects h2 {
  text-align: center;
  margin: 0;
}
@media screen and (min-width: 768px) {
  #projects-detail #project-detail-more-projects h2 {
    text-align: left;
  }
}
#services-header {
  padding-top: 30px;
}
@media screen and (min-width: 768px) {
  #services-header {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 1200px) {
  #services-header {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
#services-header .services-header-description {
  text-align: left;
}
#services-header .services-header-description span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #services-header .services-header-description span {
    font-size: 22px;
    font-weight: 500;
  }
}
#services-header .animated-dots {
  display: none;
  top: -80px;
}
@media screen and (min-width: 1200px) {
  #services-header .animated-dots {
    display: block;
  }
}
#services-header h1 {
  font-size: 40px;
  margin-bottom: 20px;
  padding-left: 15px;
}
@media screen and (min-width: 768px) {
  #services-header h1 {
    margin-left: -15px;
  }
}
@media screen and (min-width: 1200px) {
  #services-header h1 {
    margin-bottom: 0;
  }
}
#services-expertise-2 {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f5f5f5;
  min-height: 300px;
}
#services-expertise-2 .services-more-block {
  margin-bottom: 30px;
}
@media screen and (min-width: 1200px) {
  #services-expertise-2 .services-more-block {
    margin-bottom: 0;
  }
}
#services-expertise-3 {
  padding-top: 0;
}
#services-expertise-3 #expertise-header {
  padding-top: 30px;
}
@media screen and (min-width: 768px) {
  #services-expertise-3 #expertise-header {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 992px) {
  #services-expertise-3 #expertise-header {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
#services-expertise-3 #expertise-header .animated-dots {
  display: none;
  top: -80px;
}
@media screen and (min-width: 1200px) {
  #services-expertise-3 #expertise-header .animated-dots {
    display: block;
  }
}
#services-expertise-3 #expertise-header h1 {
  font-size: 40px;
  margin-bottom: 20px;
  padding-left: 15px;
}
@media screen and (min-width: 768px) {
  #services-expertise-3 #expertise-header h1 {
    margin-left: -15px;
  }
}
@media screen and (min-width: 1200px) {
  #services-expertise-3 #expertise-header h1 {
    margin-bottom: 0;
  }
}
#services-expertise-3 .expertise-header-description {
  text-align: left;
}
#services-expertise-3 .expertise-header-description span {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  #services-expertise-3 .expertise-header-description span {
    font-size: 22px;
    font-weight: 500;
  }
}
#services-expertise-3 .expertise-blocks {
  padding-bottom: 50px;
  text-align: left;
}
#services-expertise-3 .expertise-blocks h3 {
  text-align: left;
  font-size: 25px;
  z-index: 9;
  position: relative;
}
@media screen and (min-width: 786px) {
  #services-expertise-3 .expertise-blocks h3 {
    font-size: 35px;
  }
}
#services-expertise-3 .expertise-blocks h3:after {
  z-index: -1;
  content: "";
  background: #FCDC00;
  position: absolute;
  font-size: 45px;
  min-width: 56px;
  height: 15px;
  color: black;
  padding: 0px 15px;
  background-size: cover;
  margin-right: 23px;
  background-repeat: no-repeat;
  background-position-y: 40px;
  width: 100% !important;
  display: inline-block;
  text-align: center;
  top: 25px;
  left: 0;
}
#services-expertise-3 .expertise-blocks div {
  text-align: center;
  display: inline-block;
}
#services-expertise-3 .expertise-blocks div button {
  position: relative;
  text-align: left;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}
#services-expertise-3 .expertise-blocks div button:after {
  position: absolute;
  right: 15px;
  top: 12px !important;
  left: unset;
}
#services-expertise-3 .expertise-blocks div button:hover {
  transform: translateX(50px);
}
#services-expertise-3 .expertise-blocks .center {
  margin: auto auto;
  float: none;
}
#services-expertise-4 {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f5f5f5;
}
#services-more-blocks {
  position: relative;
}
#services-more-blocks .gray-stripes {
  position: absolute;
  right: -141px;
  bottom: -200px;
}
#services-more-blocks .inner {
  background: white;
  text-align: left;
  overflow: auto;
  height: 100%;
  padding-bottom: 15px;
}
#services-more-blocks .inner span {
  font-size: 17px;
}
#services-more-blocks .inner h3 {
  font-weight: 600;
  padding-left: 20px;
  font-size: 18px;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  background: black;
  width: 100%;
  color: #FCDC00;
}
#services-more-blocks .inner .inner-text {
  min-height: 300px;
  padding: 15px 15px 50px;
}
#services-more-blocks .inner .button-row {
  position: absolute;
  padding: 0 15px;
  bottom: 30px;
}
#services-more-blocks button {
  margin-left: 0;
}
@-webkit-keyframes fadeInWebsite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeInWebsite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInWebsite {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in-box-1 {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeInWebsite ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeInWebsite ease-in 1;
  animation: fadeInWebsite ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.fade-in-box-2 {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeInWebsite ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeInWebsite ease-in 1;
  animation: fadeInWebsite ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
.fade-in-box-3 {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeInWebsite ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeInWebsite ease-in 1;
  animation: fadeInWebsite ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.fade-in-box-4 {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeInWebsite ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeInWebsite ease-in 1;
  animation: fadeInWebsite ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}
@media (max-width: 1400px) {
  #services-more-blocks .gray-stripes {
    display: none;
  }
}
@media (max-width: 991px) {
  #services-header .services-header-description {
    text-align: left;
  }
}
@media (max-width: 767px) {
  #services-header {
    padding-bottom: 50px;
  }
  .expertise-blocks .center {
    margin-top: 30px !important;
  }
}
@media (max-width: 575px) {
  #expertise-header .expertise-header-description,
  #services-header .services-header-description {
    padding-left: 30px;
  }
}
@media (max-width: 500px) {
  .services-more-block button {
    display: block;
  }
}
@font-face {
  font-family: 'Segoe Script';
  src: url('./fonts/segoesc.ttf') format('truetype');
}
.about-projects-block {
  color: white;
  text-align: center;
}
.about-projects-block h2 {
  font-size: 40px;
}
.about-projects-block span {
  font-size: 17px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .about-projects-block span {
    font-size: 16px;
    font-weight: 400;
  }
}
.about-projects-block button {
  margin: auto auto !important;
  margin-top: 20px !important;
  float: none;
}
.about-projects-block .about div {
  background: url("./img/about-block-1.png");
  padding: 30px 30px;
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .about-projects-block .about div {
    padding: 50px 50px;
  }
}
@media screen and (min-width: 992px) {
  .about-projects-block .about div {
    padding: 75px 100px;
  }
}
@media screen and (min-width: 1200px) {
  .about-projects-block .about div {
    padding: 100px 150px;
  }
}
.about-projects-block .projects div {
  background: url("./img/about-block-2.png");
  padding: 30px 30px;
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .about-projects-block .projects div {
    padding: 50px 25px;
  }
}
@media screen and (min-width: 992px) {
  .about-projects-block .projects div {
    padding: 75px 100px;
  }
}
@media screen and (min-width: 1200px) {
  .about-projects-block .projects div {
    padding: 100px 130px;
  }
}
.quality-politics-component {
  padding-bottom: 50px;
}
.quality-politics-component .quality-politicts-button a {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
}
.quality-politics-component .quality-politicts-button a button {
  display: inline-block;
  background: #ffffff;
  margin: 0;
}
.quality-politics-component .animated-dots {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  top: 50%;
  transform: translate(-50%, -50%);
}
.quality-politics-component .quality-politics-header {
  margin-bottom: 15px;
}
.quality-politics-certificates {
  margin-top: 30px;
  margin-bottom: 50px;
}
.quality-politics-certificates img {
  border: 1px solid #ececec;
}
.quality-politics-certificates img:hover {
  border-color: #FCDC00;
}
#quality-policits-page-header {
  padding-top: 80px;
}
#quality-policits-page-content {
  margin-top: 50px;
}
#quality-policits-page-content .quality-politics-page-block {
  margin-top: 30px;
}
#quality-politics img {
  margin-bottom: 50px;
  width: 100%;
}
.segoe h2 {
  font-family: "Segoe Script", sans-serif;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 37px;
}
.meet-us-personal {
  background: #f5f5f5;
  padding: 100px 50px;
  text-align: left;
}
@media screen and (min-width: 1200px) {
  .meet-us-personal {
    padding: 200px 50px;
  }
}
.meet-us-personal .mobile {
  margin-right: 35px;
}
.meet-us-personal .desktop {
  margin-right: 50px;
}
.meet-us-personal h2 {
  margin-bottom: 35px;
}
.meet-us-personal a {
  margin-right: 35px;
  display: block;
}
@media screen and (min-width: 1200px) {
  .meet-us-personal a {
    display: inline-block;
  }
}
.meet-us-personal a button {
  margin-top: 30px;
  float: none;
  display: inline-block;
  background: #f5f5f5;
}
.meet-us-personal .meet-us-personal-text {
  padding-left: 50px;
}
.meet-us-personal .meet-us-personal-dots-box {
  position: relative;
}
.meet-us-personal .meet-us-personal-dots-box .animated-dots {
  display: none;
}
@media screen and (min-width: 1200px) {
  .meet-us-personal .meet-us-personal-dots-box .animated-dots {
    position: absolute;
    left: 45%;
    top: 15px;
    display: block;
  }
}
.our-process-icons {
  margin-top: 50px;
}
.our-process-icons img {
  height: 150px;
  max-width: unset !important;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}
.our-process-icons .row-1 {
  margin: auto auto;
}
.our-process-icons .row-2 {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .our-process-icons .row-2 {
    margin-top: 50px;
  }
}
.our-process-icons .our-process-icon-holder {
  text-align: center;
}
.our-process-icons .our-process-icon-holder span {
  color: white;
}
.our-process-icons .white-bubbler {
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  padding: 3px 0;
  width: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .our-process-icons .white-bubbler {
    padding: 7px 0;
    width: 42px;
    font-size: 19px;
  }
}
#more-projects {
  position: relative;
  text-align: left;
  padding: 0 30px;
  margin: 30px -15px 0;
}
@media screen and (min-width: 768px) {
  #more-projects {
    padding: 0;
    margin: 30px 0 0;
  }
}
#more-projects h3 {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 21px;
}
#more-projects span {
  font-size: 14px;
}
#more-projects img {
  max-width: 100%;
}
#more-projects .slick-list {
  margin: 0 -15px;
}
#more-projects .more-projects-block {
  padding: 15px;
}
#more-projects a {
  color: #000000;
}
.bim-projecting {
  text-align: left;
}
.bim-projecting img {
  max-width: 100%;
}
.bim-projecting .bim-list-inner {
  width: calc(100% - 40px);
  display: inline-block;
}
.bim-projecting .bim-header {
  padding-bottom: 30px;
  padding-top: 50px;
}
.bim-projecting .bim-image > div {
  position: relative;
  height: 100%;
}
.bim-projecting .bim-image > div > #bim_video {
  width: 100%;
  height: 260px;
}
@media screen and (min-width: 768px) {
  .bim-projecting .bim-image > div > #bim_video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.bim-projecting ul {
  list-style-type: none;
  padding-left: 0;
}
@media screen and (min-width: 992px) {
  .bim-projecting ul {
    margin-left: 20px;
  }
}
.bim-projecting ul li {
  position: relative;
  font-size: 20px;
  line-height: 36px;
}
@media screen and (min-width: 768px) {
  .bim-projecting ul li {
    margin-bottom: 20px;
  }
}
.bim-projecting ul li:before {
  background: url("./img/arrow-right-bim.svg") no-repeat;
  background-size: cover;
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
  vertical-align: top;
}
@media screen and (min-width: 768px) {
  .bim-projecting ul li:before {
    width: 20px;
    height: 20px;
    margin-top: 8px;
  }
}
.bim-projecting .bim-bottom {
  margin-top: 50px;
}
@media screen and (min-width: 768px) {
  .bim-projecting .bim-bottom {
    margin-bottom: 50px;
  }
}
.bim-projecting .bim-bottom .animated-dots {
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .bim-projecting .bim-bottom .bim-bottom-text {
    padding: 0 47px;
  }
}
.bim-projecting .bim-bottom .bim-more-btn {
  padding: 20px 47px 50px;
}
@media screen and (min-width: 992px) {
  .bim-projecting .bim-bottom .bim-more-btn {
    padding: 0 15px;
    height: 170px;
  }
}
@media screen and (min-width: 992px) {
  .bim-projecting .bim-bottom .bim-more-btn a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}
.bim-projecting .bim-bottom .bim-more-btn a button {
  display: inline-block;
  background: #ffffff;
  margin: 0;
}
.bim-projecting .bim-bottom .bim-more-btn a button:hover {
  background: black;
}
@media (max-width: 1199px) {
  .bim-projecting ul li {
    font-size: 28px;
  }
}
@media (max-width: 991px) {
  .bim-projecting ul li {
    font-size: 19px;
  }
  .bim-projecting li:before {
    top: 6px;
  }
  .our-process-icons img {
    height: 100px;
    max-width: unset !important;
  }
  .bim-projecting .bim-more-btn .animated-dots {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .home#home-process section {
    padding-left: 0;
    text-align: left;
  }
  .our-process-icons .our-process-icon-holder {
    width: 50%;
    text-align: center;
    margin-top: 15px;
  }
  .our-process-icons {
    padding: 0;
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .our-process-icons .our-process-icon-holder {
    width: 50%;
  }
  .bim-projecting .bim-header {
    padding-left: 0;
  }
  .bim-projecting .bim-list {
    margin-top: 50px;
  }
  .about-projects-block .projects {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .bim-image {
    padding: 0;
  }
}
@media (max-width: 500px) {
  .home#home-process section {
    padding-left: 0;
  }
}
@media (max-width: 400px) {
  .our-process-icons img {
    height: 104px;
    max-width: unset !important;
  }
}
#consultation-content {
  text-align: left;
  background: #f5f5f5;
  padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
  #consultation-content {
    padding-top: 30px;
  }
}
#consultation-content img {
  margin-top: 50px;
  margin-bottom: 75px;
}
#consultation-content span {
  font-size: 18px;
}
#consultation-content .third-row {
  margin-top: 50px;
}
#consultation-content h3 {
  position: relative;
  font-weight: bold;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 40px;
}
@media screen and (min-width: 992px) {
  #consultation-content h3:before {
    content: url("./img/arrow-right-black.svg");
    /* FontAwesome Unicode */
    width: 19px;
    height: 19px;
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
  }
}
.animated-dots {
  position: absolute;
  overflow: visible;
}
.animated-dots .animated-dots__dot {
  position: absolute;
  border-radius: 50%;
  background: #fdde00;
}
.animated-dots.animated-dots--gray .animated-dots__dot {
  background: #9a9a9a;
}
.animated-dots.animated-dots--white .animated-dots__dot {
  background: #ffffff;
}
#gdpr {
  padding-top: 150px;
  padding-bottom: 100px;
}
#gdpr p,
#gdpr li,
#gdpr span {
  font-size: 18px;
}
#gdpr a {
  color: black;
}
#gdpr a:hover {
  color: #FCDC00;
}
#gdpr h1 {
  font-size: 65px;
  text-align: left !important;
}
.btn-right-black {
  float: none;
  background: white;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  color: black;
  border: 3px solid black;
  padding: 5px;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 21px;
  font-size: 20px;
  padding-top: 0;
}
.btn-right-black span {
  font-size: 18px;
}
.btn-right-black span:after {
  content: url("./img/arrow-right-black.svg");
  /* FontAwesome Unicode */
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 11px;
  left: 0px;
  position: relative;
  top: 6px;
}
.container#privacy-policy {
  padding-top: 155px;
  padding-bottom: 70px;
}
@media (max-width: 1080px) {
  .header-container {
    padding-right: 80px !important;
  }
}
@media (max-width: 991px) {
  .header-container {
    padding-right: 0px !important;
  }
}
@media (max-width: 575px) {
  .main-menu-ul li {
    margin-right: 25px;
  }
}
